<template>
    <nav id="menu" class="nav-main" role="navigation" style="min-height:100vh">
        <ul class="nav nav-main">
            <li v-bind:class="dashboardPage">
                <router-link to="/dashboard">
                    <i class="fa fa-home" aria-hidden="true"></i>
                    <span>Dashboard</span>
                </router-link>
            </li>
            <li v-bind:class="newAdvertPage">
                <router-link to="/new-advert">
                    <i class="fa fa-share-alt-square" aria-hidden="true"></i>
                    <span>Create Adverts</span>
                </router-link>
            </li>
            <li v-bind:class="walletPage">
                <router-link to="/wallet">
                    <i class="fa fa-lightbulb-o" aria-hidden="true"></i>
                    <span>Wallet</span>
                </router-link>
            </li>
            <li v-bind:class="historyPage">
                <router-link to="/history">
                    <i class="fa  fa-database" aria-hidden="true"></i>
                    <span>History</span>
                </router-link>
            </li>
            <li v-bind:class="profilePage">
                <router-link to="/profile">
                    <i class="fa  fa-user" aria-hidden="true"></i>
                    <span>Profile</span>
                </router-link>
            </li>
            <li v-bind:class="supportPage">
                <router-link to="/support">
                    <i class="fa fa-support" aria-hidden="true"></i>
                    <span>Support</span>
                </router-link>
            </li>
            <li class="" style="position: fixed;bottom: 10px;background: #023562;">
                <a class="mouse-pointer" v-on:click="logout">
                    <i class="fa fa-sign-out" aria-hidden="true"></i>
                    <span>Log Out</span>
                </a>
            </li>
        </ul>
    </nav>
</template>
<script>
export default {
    data: () => ({
        pageName: "",
        dashboardPage:"",
        newAdvertPage:"",
        newAdvertTypePage:"",
        walletPage:"",
        historyPage:"",
        profilePage:"",
        supportPage:"",
    }),
    mounted () {
        this.getPageName()
    },
    methods: {
        getPageName(){
            let l = window.location.pathname
            console.log(l)
            let splitL = l.split('/')
            let pageName = splitL[1]
            console.log(splitL,pageName)
            this.pageName = pageName
            this.resetSB()
            this.loadActiveSB()
        },
        resetSB(){
            this.dashboardPage = ""
            this.newAdvertPage = ""
            this.newAdvertTypePage = ""
            this.walletPage = ""
            this.historyPage = ""
            this.profilePage = ""
            this.supportPage = ""
        },
        loadActiveSB(){
            if(this.pageName == 'dashboard'){
                this.dashboardPage = 'nav-active'
            }
            if(this.pageName == 'new-advert'){
                this.newAdvertPage = 'nav-active'
            }
            if(this.pageName == 'new-advert-type'){
                this.newAdvertTypePage = 'nav-active'
            }
            if(this.pageName == 'wallet'){
                this.walletPage = 'nav-active'
            }
            if(this.pageName == 'history'){
                this.historyPage = 'nav-active'
            }
            if(this.pageName == 'profile'){
                this.profilePage = 'nav-active'
            }
            if(this.pageName == 'support'){
                this.supportPage = 'nav-active'
            }
        },
        logout () {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push({ path: '/login' })
            })
        }
    }

};
</script>
<style type="text/css">
</style>
